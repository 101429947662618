import React from "react";
import { RootStackScreenProps } from "../types";
import { ApiController, Dialog, Configuration } from "../components/BrandDialog";
import { Button, View } from "native-base";

export default function LoadingScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [activity, setActivity] = React.useState(false);
  return (
    <Dialog activity={false} title="My account">
      <View style={{ alignItems: "center", padding: 10 }}>
        <Button
          style={{ maxWidth: 200 }}
          colorScheme="danger"
          disabled={activity}
          onPress={() => {
            setActivity(true);
            ApiController.logout(() => {
              navigation.navigate("Login");
            });
          }}
        >
          Logout
        </Button>
      </View>
    </Dialog>
  );
}
