import React from "react";
import { RootStackScreenProps } from "../types";
import { ApiController, Dialog, Configuration } from "../components/BrandDialog";
import { useEffect } from "react";

export default function LoadingScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [activity, setActivity] = React.useState(false);
  useEffect(() => {
    setActivity(true);
    ApiController.me().then(info => {
      if (info.status !== 200) {
        navigation.replace("Login");
      } else {
        navigation.replace("Welcome");
      }
    });
  });
  return <Dialog activity={activity}></Dialog>;
}
