import * as React from "react";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import LoopingzCircleLogo from "../components/logos/LoopingzCircleLogo";
import LoopingzLogo from "../components/logos/LoopingzLogo";
import deepmerge from "deepmerge";
import { useBreakpointValue } from "native-base";
import Dialog from "./Dialog";
import { RootStackScreenProps } from "../types";
import Controller from "../api/Controller";

const Configuration = {
  sso: ["Google"],
  email: true
};

const ApiController = new Controller();

export default function LoopingzDialog({ navigation, children, ...props }: any) {
  const { height, width } = useWindowDimensions();
  const styles = useBreakpointValue({
    base: deepmerge(baseStyles, {
      type: "base",
      header: {
        height: 180,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      logo: {
        top: -100
      }
    }),
    md: deepmerge(baseStyles, {
      type: "md",
      logo: {
        top: -80
      },
      header: {
        ...baseStyles.header,
        paddingLeft: 20,
        paddingRight: 20
      }
    })
  });
  return (
    <Dialog
      {...props}
      headerContent={
        <View>
          <View
            style={{ position: "absolute", left: ((width > 750 ? 750 : width - 20) - 150) / 2, zIndex: 2, top: -80 }}
          >
            <LoopingzCircleLogo width={150} height={150} />
          </View>
          <View style={styles.header}>
            <LoopingzLogo width={140} height={140 / (86.01 / 24.35)} />
            <Text style={styles.title}>Authentication</Text>
          </View>
        </View>
      }
    >
      {children}
    </Dialog>
  );
}

const baseStyles = {
  header: {
    backgroundColor: "#404041",
    color: "white",
    borderWidth: 2,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 30
  },
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white"
  },
  link: {
    alignItems: "flex-end",
    marginBottom: 30
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
};

export { Configuration, LoopingzDialog as Dialog, ApiController };
