type Callback = (...args: any[]) => void;

export default class AuthenticationController {
  static getBaseUrl(hostname: string, apiName?: string = "api") {
    return hostname.replace(/^(beta\.)?.*(\.[a-zA-Z0-9_\-]+\.[a-z]+)$/, `$1${apiName}$2`);
  }

  baseUrl: string;
  constructor(baseUrl?: string, apiName?: string = "api") {
    if (baseUrl) {
      this.baseUrl = baseUrl;
    } else {
      if (window.location.protocol !== "https:") {
        this.baseUrl = `http://${window.location.hostname}:18080`;
      } else {
        // Use api by default
        this.baseUrl = `https://${AuthenticationController.getBaseUrl(window.location.hostname, apiName)}`;
      }
    }

    if (this.baseUrl.endsWith("/")) {
      this.baseUrl = this.baseUrl.substr(0, this.baseUrl.length - 1);
    }
  }

  http(url: string, options: any = {}) {
    options.headers = options.headers ?? {};
    if (typeof options.body === "object") {
      options.body = JSON.stringify(options.body);
      options.headers["content-type"] = "application/json";
    }
    return fetch(this.getUrl(url), { credentials: "include", ...options });
  }

  /**
   * Get current user
   * @returns
   */
  me() {
    return this.http("/auth/me");
  }

  /**
   * Log the user out
   * @returns
   */
  async logout(callback?: Callback): Promise<void> {
    let promise = this.http("/auth", { method: "DELETE" });
    await promise;
    callback ? callback() : "";
  }

  /**
   * Get an url for the controller
   *
   * If the url is absolute will just return the url back
   * @param url
   * @returns
   */
  getUrl(url: string) {
    // Add url
    if (!url.startsWith("http")) {
      url = `${this.baseUrl}/${url.startsWith("/") ? url.substr(1) : url}`;
    }
    return url;
  }

  async newRegistration(login: string, callback?: Callback) {
    let res = await this.http("/auth/email", {
      method: "POST",
      body: {
        login,
        register: true,
        // TODO Remove with @webda/core 1.5.2
        password: ""
      }
    });
    callback ? callback(res) : "";
    return res;
  }

  async registration(login: string, token: string, password: string, profile: any, callback?: Callback) {
    let res = await this.http("/auth/email", {
      method: "POST",
      body: {
        login,
        register: true,
        token,
        // TODO Remove with @webda/core 1.5.2
        password,
        ...profile
      }
    });
    callback ? callback(res) : "";
    return res;
  }

  async login(login: string, password: string, callback?: Callback) {
    let res = await this.http("/auth/email", {
      method: "POST",
      body: {
        login,
        password
      }
    });
    callback ? callback(res) : "";
    return res;
  }

  async changePassword(login: string, token: string, expire: string, password: string, callback?: Callback) {
    let res = await this.http("/auth/email/passwordRecovery", {
      method: "POST",
      body: {
        login,
        password,
        token,
        expire
      }
    });
    callback ? callback(res) : "";
    return res;
  }

  async lostPasswordRequest(email: string, callback?: Callback) {
    let res = await this.http(`/auth/email/${email}/recover`);
    callback ? callback(res) : "";
    return res;
  }
}
