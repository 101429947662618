import React, { useEffect } from "react";
import { View, Platform, Linking } from "react-native";
import { Input, Icon, Button, useBreakpointValue, FormControl } from "native-base";
import { RootStackScreenProps } from "../types";
import { MaterialIcons } from "@expo/vector-icons";
import deepmerge from "deepmerge";
import { ApiController, Dialog, Configuration } from "../components/BrandDialog";
import { isValidEmail } from "./LoginScreen";

export default function LoginScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [message, setMessage] = React.useState<Message | undefined>(undefined);
  const [activity, setActivity] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [token, setToken] = React.useState("");
  const [profile, setProfile] = React.useState<any>({});
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  useEffect(() => {
    if (Platform.OS === "web") {
      let params = new URLSearchParams(window.location.search);
      setEmail(params.get("email") || "");
      setToken(params.get("token") || "");
    }
  });
  const styles = useBreakpointValue({
    base: deepmerge(baseStyles, {
      type: "base",
      dividers: "horizontal",
      direction: "column",
      loginBox: {
        padding: 3,
        paddingTop: 20
      },
      credentialsBox: {
        paddingHorizontal: 10
      },
      header: {
        height: 180,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      logo: {
        top: -100
      }
    }),
    md: deepmerge(baseStyles, {
      type: "md",
      dividers: "vertical",
      direction: "row",
      logo: {
        top: -80
      },
      loginBox: {
        width: 750
      },
      ssoBox: {
        padding: 20
      },
      header: {
        ...baseStyles.header,
        paddingLeft: 20,
        paddingRight: 20
      }
    })
  });
  let isFormInvalid = activity || !isValidEmail(email) || password !== password2 || password.length < 8;
  return (
    <Dialog activity={activity} message={message}>
      <View style={{ padding: 20, alignItems: "center" }}>
        <View style={{ maxWidth: 400 }}>
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              w="100%"
              placeholder="Email"
              type="email"
              textContentType="username"
              autoCompleteType="email"
              isReadOnly={true}
              value={email}
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="email" />}
                  size="sm"
                  style={{ marginLeft: 5 }}
                  _light={{
                    color: "gray.500"
                  }}
                  _dark={{
                    color: "gray.300"
                  }}
                />
              }
            />
          </FormControl>
          <FormControl style={{ paddingTop: 10 }} isRequired isInvalid={password.length > 0 && password.length < 8}>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              w="100%"
              placeholder="Password"
              textContentType="password"
              autoCompleteType="password"
              secureTextEntry
              onChange={(evt: any) => {
                setPassword(evt.target.value);
              }}
              InputLeftElement={
                <Icon
                  size="sm"
                  as={<MaterialIcons name="lock" />}
                  style={{ marginLeft: 5 }}
                  _light={{
                    color: "gray.500"
                  }}
                  _dark={{
                    color: "gray.300"
                  }}
                />
              }
            />
          </FormControl>
          <FormControl style={{ paddingBottom: 10 }} isInvalid={password2.length > 0 && password !== password2}>
            <Input
              w="100%"
              placeholder="Confirm Password"
              textContentType="password"
              autoCompleteType="password"
              secureTextEntry
              onChange={(evt: any) => {
                setPassword2(evt.target.value);
              }}
              isRequired={true}
              InputLeftElement={
                <Icon
                  size="sm"
                  as={<MaterialIcons name="lock" />}
                  style={{ marginLeft: 5 }}
                  _light={{
                    color: "gray.500"
                  }}
                  _dark={{
                    color: "gray.300"
                  }}
                />
              }
            />
            <FormControl.ErrorMessage>Passwords need to match.</FormControl.ErrorMessage>
          </FormControl>
          {Configuration.profileFields.map(field => {
            let isInvalid = field.regex ? field.regex.exec(profile[field.name]) === null : false;
            if (!field.optional && !profile[field.name]) {
              isFormInvalid = true;
            }
            isFormInvalid = isFormInvalid || isInvalid;
            return (
              <FormControl
                style={{ paddingVertical: 10 }}
                isRequired={!(field.optional ?? false)}
                isInvalid={isInvalid}
              >
                <FormControl.Label>{field.label || field.name}</FormControl.Label>
                <Input
                  w="100%"
                  placeholder={field.placeholder ?? field.label ?? field.name}
                  textContentType={field.textContentType || "none"}
                  autoCompleteType={field.autoCompleteType || "off"}
                  secureTextEntry={field.autoCompleteType === "password"}
                  onChange={(evt: any) => {
                    let p = { ...profile };
                    p[field.name] = evt.target.value;
                    setProfile(p);
                  }}
                  InputLeftElement={
                    field.icon ? (
                      <Icon
                        size="sm"
                        // @ts-ignore
                        as={<MaterialIcons name={field.icon} />}
                        style={{ marginLeft: 5 }}
                        _light={{
                          color: "gray.500"
                        }}
                        _dark={{
                          color: "gray.300"
                        }}
                      />
                    ) : undefined
                  }
                />
                <FormControl.ErrorMessage>Invalid value.</FormControl.ErrorMessage>
              </FormControl>
            );
          })}

          <Button
            disabled={isFormInvalid}
            style={styles.ssoButton}
            onPress={async () => {
              setActivity(true);
              setMessage(undefined);
              let res = await ApiController.registration(email, token, password, profile);
              if (res.status === 410) {
                setActivity(false);
                setMessage({
                  type: "ERROR",
                  text: `You are already logged in`
                });
              } else if (res.status === 409) {
                setActivity(false);
                setMessage({
                  type: "ERROR",
                  text: `The email ${email} is already registered, please use password recovery`
                });
              } else if (res.status < 300) {
                setMessage({
                  type: "SUCCESS",
                  text: `Your account got created successfully`
                });
                setTimeout(() => {
                  Linking.openURL(ApiController.getUrl("/auth/redirect"));
                }, 1500);
              } else {
                setActivity(false);
                setMessage({
                  type: "ERROR",
                  text: `${res.status}: Unknown error occured`
                });
              }
            }}
          >
            Register
          </Button>
        </View>
      </View>
    </Dialog>
  );
}

const baseStyles = {
  ssoBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  header: {
    backgroundColor: "#404041",
    color: "white",
    borderWidth: 2,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 30
  },
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  ssoButton: {
    margin: 5
  },
  ssoButtons: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between"
  },
  credentialsBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  loginBox: {
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white"
  },
  link: {
    alignItems: "flex-end",
    marginBottom: 30
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
};
