import * as React from "react";
import { Text, TouchableOpacity, View, useWindowDimensions, Platform } from "react-native";
import { Input, Icon, Button, Divider, PresenceTransition, Stack, useBreakpointValue, FormControl } from "native-base";
import { RootStackScreenProps } from "../types";
import { MaterialIcons } from "@expo/vector-icons";
import LoopingzCircleLogo from "../components/logos/LoopingzCircleLogo";
import LoopingzLogo from "../components/logos/LoopingzLogo";
import GoogleLoginButton from "../components/GoogleSignInButton";
import FacebookLoginButton from "../components/FacebookLoginButton";
import deepmerge from "deepmerge";
import { Configuration, Dialog, ApiController } from "../components/BrandDialog";
import { useEffect } from "react";

export default function LoginScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [message, setMessage] = React.useState<Message | undefined>(undefined);
  const [activity, setActivity] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");

  const [login, setLogin] = React.useState("");
  const [token, setToken] = React.useState("");
  const [expire, setExpire] = React.useState("");

  useEffect(() => {
    if (Platform.OS === "web") {
      let params = new URLSearchParams(window.location.search);
      setLogin(params.get("login") || "");
      setToken(params.get("token") || "");
      setExpire(params.get("expire") || "");
    }
  });

  const { height, width } = useWindowDimensions();
  const styles = useBreakpointValue({
    base: deepmerge(baseStyles, {
      type: "base",
      dividers: "horizontal",
      direction: "column",
      loginBox: {
        padding: 3,
        paddingTop: 20
      },
      credentialsBox: {
        paddingHorizontal: 10
      },
      header: {
        height: 180,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      logo: {
        top: -100
      }
    }),
    md: deepmerge(baseStyles, {
      type: "md",
      dividers: "vertical",
      direction: "row",
      logo: {
        top: -80
      },
      loginBox: {
        width: 750
      },
      ssoBox: {
        padding: 20
      },
      header: {
        ...baseStyles.header,
        paddingLeft: 20,
        paddingRight: 20
      }
    })
  });
  return (
    <Dialog activity={activity} message={message} title="Lost Password">
      <View style={{ padding: 20 }}>
        <Text style={{ padding: 20 }}>
          You have requested a password change, if this is not the case please discard.
        </Text>
        <FormControl style={{ paddingTop: 10 }} isRequired isInvalid={password.length > 0 && password.length < 8}>
          <FormControl.Label>Password</FormControl.Label>
          <Input
            w="100%"
            placeholder="Password"
            textContentType="password"
            autoCompleteType="password"
            secureTextEntry
            onChange={(evt: any) => {
              setPassword(evt.target.value);
            }}
            InputLeftElement={
              <Icon
                size="sm"
                as={<MaterialIcons name="lock" />}
                style={{ marginLeft: 5 }}
                _light={{
                  color: "gray.500"
                }}
                _dark={{
                  color: "gray.300"
                }}
              />
            }
          />
        </FormControl>
        <FormControl style={{ paddingBottom: 10 }} isInvalid={password2.length > 0 && password !== password2}>
          <Input
            w="100%"
            placeholder="Confirm Password"
            textContentType="password"
            autoCompleteType="password"
            secureTextEntry
            onChange={(evt: any) => {
              setPassword2(evt.target.value);
            }}
            isRequired={true}
            InputLeftElement={
              <Icon
                size="sm"
                as={<MaterialIcons name="lock" />}
                style={{ marginLeft: 5 }}
                _light={{
                  color: "gray.500"
                }}
                _dark={{
                  color: "gray.300"
                }}
              />
            }
          />
          <FormControl.ErrorMessage>Passwords need to match.</FormControl.ErrorMessage>
        </FormControl>
        <Button
          disabled={activity || password !== password2 || password.length < 8}
          style={styles.ssoButton}
          onPress={async () => {
            setActivity(true);
            setMessage(undefined);
            let res = await ApiController.changePassword(login, token, expire, password);
            setActivity(false);
            if (res.status < 300) {
              setMessage({
                type: "SUCCESS",
                text: "Password successful changed, you can go back to your app and login"
              });
            } else if (res.status === 403 || res.status === 400) {
              setMessage({
                type: "ERROR",
                text: "The link is invalid"
              });
            } else if (res.status === 410) {
              setMessage({
                type: "ERROR",
                text: "The link has expired"
              });
            } else {
              setMessage({
                type: "ERROR",
                text: `${res.status}: Unknown error occured`
              });
            }
          }}
        >
          Change my password
        </Button>
      </View>
    </Dialog>
  );
}

const baseStyles = {
  ssoBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  header: {
    backgroundColor: "#404041",
    color: "white",
    borderWidth: 2,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 30
  },
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  ssoButton: {
    margin: 5
  },
  ssoButtons: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between"
  },
  credentialsBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  loginBox: {
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white"
  },
  link: {
    alignItems: "flex-end",
    marginBottom: 30
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
};
