import * as React from "react";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { Input, Icon, Button, Divider, PresenceTransition, Stack, useBreakpointValue } from "native-base";
import { RootStackScreenProps } from "../types";
import { MaterialIcons } from "@expo/vector-icons";
import { ApiController, Dialog, Configuration } from "../components/BrandDialog";
import { isValidEmail } from "./LoginScreen";

export default function LoginScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [login, setLogin] = React.useState("");
  const [message, setMessage] = React.useState<Message | undefined>(undefined);
  const [activity, setActivity] = React.useState(false);
  const styles = baseStyles;
  return (
    <Dialog activity={activity} message={message} title="Registration">
      <View style={{ padding: 20, alignItems: "center" }}>
        <Text style={{ padding: 20 }}>
          The registration process will be done in two step, please fill your email and we will send you a validation
          email with a link to finish the registration process
        </Text>
        <Input
          w="100%"
          placeholder="Email"
          type="email"
          textContentType="username"
          autoCompleteType="email"
          keyboardType="email-address"
          onChange={(evt: any) => {
            setLogin(evt.target.value);
          }}
          InputLeftElement={
            <Icon
              size="sm"
              as={<MaterialIcons name="email" />}
              style={{ marginLeft: 5 }}
              _light={{
                color: "gray.500"
              }}
              _dark={{
                color: "gray.300"
              }}
            />
          }
        />
        <Button
          disabled={activity || !isValidEmail(login)}
          style={styles.ssoButton}
          onPress={async () => {
            setActivity(true);
            setMessage(undefined);
            let res = await ApiController.newRegistration(login);
            setActivity(false);
            if (res.status === 410) {
              setMessage({
                type: "ERROR",
                text: `You are already logged in`
              });
            } else if (res.status === 409) {
              setMessage({
                type: "ERROR",
                text: `The email ${login} is already registered, please use password recovery`
              });
            } else if (res.status < 300) {
              setMessage({
                type: "SUCCESS",
                text: `An email has been sent to ${login}`
              });
            } else {
              setMessage({
                type: "ERROR",
                text: `${res.status}: Unknown error occured`
              });
            }
          }}
        >
          Register
        </Button>
      </View>
    </Dialog>
  );
}

const baseStyles = {
  ssoBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  header: {
    backgroundColor: "#404041",
    color: "white",
    borderWidth: 2,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 30
  },
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  ssoButton: {
    margin: 5,
    maxWidth: 500,
    marginTop: 20
  },
  ssoButtons: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between"
  },
  credentialsBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  loginBox: {
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white"
  },
  link: {
    alignItems: "flex-end",
    marginBottom: 30
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
};
