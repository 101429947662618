import * as React from "react";
import { Text, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { Input, Icon, Button, Divider, PresenceTransition, Stack, useBreakpointValue } from "native-base";
import { RootStackScreenProps } from "../types";
import { MaterialIcons } from "@expo/vector-icons";
import deepmerge from "deepmerge";
import { AnimatedActivity } from "../components/gradient/activity";
import { Component } from "react";

interface BrandDialogProperties {
  headerContent: any;
  children: any | any[];
  message?: Message;
  activity?: boolean;
}

export interface Message {
  type?: "ERROR" | "WARN" | "INFO" | "SUCCESS";
  text: string;
}

const messagesStyle = {
  ERROR: {
    backgroundColor: "#ff4444",
    borderTopColor: "#CC0000",
    color: "white"
  },
  WARN: {
    backgroundColor: "#ffbb33",
    borderTopColor: "#FF8800",
    color: "white"
  },
  INFO: {
    backgroundColor: "#33b5e5",
    borderTopColor: "#0099CC",
    color: "white"
  },
  SUCCESS: {
    backgroundColor: "#00C851",
    borderTopColor: "#007E33",
    color: "white"
  }
};

export default function Dialog({
  headerContent,
  children,
  message,
  activity = false,
  style = {}
}: BrandDialogProperties) {
  const styles = useBreakpointValue({
    base: deepmerge(baseStyles, style, {
      brandDialog: {
        width: "100%"
      },
      container: {
        padding: 0
      }
    }),
    md: deepmerge(baseStyles, style)
  });
  return (
    <View style={styles.container}>
      <View style={styles.brandDialog}>
        <View style={styles.headerPanel}>{headerContent}</View>
        <View style={{ height: 5 }}>
          <AnimatedActivity colors={["#00AEEF", "#fff"]} animate={activity} style={{ flex: 1, height: 5 }} />
        </View>
        <View style={styles.content}>{children}</View>
        <View style={{ minHeight: 40, borderBottomWidth: 0, borderBottomLeftRadius: 15 }}>
          <PresenceTransition
            initial={{ opacity: 0 }}
            style={{ minHeight: 40 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 250
              }
            }}
            visible={message !== undefined}
          >
            <View
              style={{
                padding: 5,
                alignItems: "center",
                justifyContent: "center",
                borderTopWidth: 2,
                minHeight: 40,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                // @ts-ignore
                ...messagesStyle[message !== undefined ? message.type : "INFO"]
              }}
            >
              <Text style={messagesStyle[message !== undefined ? message.type : "INFO"]}>
                {message ? message.text : ""}
              </Text>
            </View>
          </PresenceTransition>
        </View>
      </View>
    </View>
  );
}

const baseStyles = {
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  content: {},
  brandDialog: {
    backgroundColor: "#fff",
    borderColor: "rgba(64,64,65,.2)",
    borderWidth: 2,
    borderRadius: 15,
    elevation: 1,
    shadowColor: "#457aff4f",
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 20,
    maxWidth: 800,
    width: 750
  },
  // boxShadow: "0px 0px 20px #457aff4f;",
  container: {
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  }
};
