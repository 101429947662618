import * as React from "react";
import Svg, { SvgProps, Stop, RadialGradient, Path, Use, G } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props: SvgProps) {
  const id = props.id || `svg_${Math.floor(Math.random() * 100000)}`;
  return (
    <Svg viewBox="0 0 86.01 24.35" {...props}>
      <RadialGradient id={`${id}_gradient`} gradientUnits="userSpaceOnUse" cx={23} cy={12} r={16}>
        <Stop offset="8%" stopColor="#fff" />
        <Stop offset="95%" stopColor="#00AEEF" />
      </RadialGradient>
      <Use
        xlinkHref="#prefix__SVGID_00000054232198211360900950000007763365036801809329_"
        overflow="visible"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        stroke="#00aeef"
        strokeWidth={0.1}
        strokeMiterlimit={22.926}
      />
      <G>
        {/* L Z */}
        <Path
          fill="#fff"
          d="M74.54 15.48v2.25h11.47v-2.25H77.5l7.92-9.22H74.54v2.25h5.98zM2.25 12.18V0H0v12.18c0 3.26 2.65 5.91 5.91 5.91 3.26 0 5.91-2.65 5.91-5.91H9.57c0 2.02-1.64 3.66-3.66 3.66s-3.66-1.64-3.66-3.66"
        />
        {/* G */}
        <Path
          fill="#fff"
          d="M68.07 6.27c1.38 0 2.65.47 3.66 1.27V6.26h2.25v12.17c0 3.26-2.65 5.91-5.91 5.91-2.25 0-4.21-1.26-5.21-3.12l1.82-1.41a3.662 3.662 0 007.05-1.39V16.8a5.927 5.927 0 01-3.66 1.27c-3.26 0-5.91-2.65-5.91-5.91 0-3.24 2.64-5.89 5.91-5.89m0 2.25c2.02 0 3.66 1.64 3.66 3.66 0 2.02-1.64 3.66-3.66 3.66-2.02 0-3.66-1.64-3.66-3.66 0-2.02 1.63-3.66 3.66-3.66z"
        />
        <Path stroke="#404041" fill="#404041" d="M64.70 12 a 3.5,3.5 0 1,0 7,0 a 3.5,3.5 0 1,0 -7,0" />
        {/* I */}
        <Path fill="#fff" d="M46.86 6.26h2.25v11.47h-2.25z" />
        {/* P N et point du i */}
        <Path
          fill="#fff"
          d="M47.99 18.42c.93 0 1.69.75 1.69 1.69 0 .93-.75 1.69-1.69 1.69-.93 0-1.69-.76-1.69-1.69 0-.93.76-1.69 1.69-1.69M40.34 6.27c-1.38 0-2.65.47-3.66 1.27V6.26h-2.25v18.08h2.25v-7.53c1.01.79 2.28 1.27 3.66 1.27 3.26 0 5.91-2.65 5.91-5.91 0-3.25-2.65-5.9-5.91-5.9m0 2.25c-2.02 0-3.66 1.64-3.66 3.66 0 2.02 1.64 3.66 3.66 3.66 2.02 0 3.66-1.64 3.66-3.66 0-2.02-1.64-3.66-3.66-3.66zM55.63 6.26c3.21 0 5.81 2.55 5.91 5.73V17.72h-2.25v-5.56c0-2.02-1.64-3.66-3.66-3.66-2.02 0-3.66 1.64-3.66 3.66v5.56h-2.25V6.26h2.25v1.27a5.927 5.927 0 013.66-1.27"
        />
        <Path stroke="#404041" fill="#404041" d="M37.10 12 a 3.5,3.5 0 1,0 7,0 a 3.5,3.5 0 1,0 -7,0" />
        {/* infini */}
        <Path
          d="M18.34 6.27c-3.26 0-5.91 2.65-5.91 5.91 0 3.26 2.65 5.91 5.91 5.91 3.26 0 5.91-2.65 5.91-5.91 0-2.02 1.64-3.66 3.66-3.66s3.66 1.64 3.66 3.66a3.662 3.662 0 01-6.56 2.24c-.26.76-.64 1.47-1.13 2.09 1.05.98 2.47 1.58 4.02 1.58 3.26 0 5.91-2.65 5.91-5.91 0-3.26-2.65-5.91-5.91-5.91-3.26 0-5.91 2.65-5.91 5.91 0 2.02-1.64 3.66-3.66 3.66-2.02 0-3.66-1.64-3.66-3.66 0-2.02 1.64-3.66 3.66-3.66 1.18 0 2.23.56 2.89 1.42.26-.77.64-1.47 1.13-2.09a5.84 5.84 0 00-4.01-1.58z"
          fill={`url(#${id}_gradient)`}
          stroke="#00aeef"
          strokeWidth={0.003}
          strokeMiterlimit={22.926}
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
