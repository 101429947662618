import React, { Component } from "react";
import { LinearGradient } from "expo-linear-gradient";

export class ActivityHelper extends Component {
  render() {
    const { style, distance, opacity } = this.props;
    return (
      <LinearGradient
        locations={[distance - 0.25, distance, distance + 0.05, distance + 0.25]}
        colors={["#00AEEF", "#fff", "#fff", "#00AEEF"]}
        start={{
          x: 0.0,
          y: 0
        }}
        end={{
          x: 1,
          y: 0.05
        }}
        style={{ ...style, opacity }}
      />
    );
  }
}
