import React from "react";
import { View, Text, Button, TouchableOpacity } from "react-native";
import {LinearGradient} from 'expo-linear-gradient';
import { styles } from "styled-system";
import { FontAwesome } from "@expo/vector-icons";

export default function ({onPress, disabled, ...props} : {onPress: () => void, disabled: boolean, [key:string]: any}) {
  return (
    <FontAwesome.Button iconStyle={{marginLeft: 5, marginRight: 10}} name="facebook" backgroundColor="#3b5998" onPress={onPress}>
        Login with Facebook
      </FontAwesome.Button>);
}
