import React, { Component } from "react";
import { StyleSheet, Animated } from "react-native";
import { ActivityHelper } from "./activity-helper";

const styles = StyleSheet.create({
  component: {
    flex: 1
  }
});

const AnimatedActivityHelper = Animated.createAnimatedComponent(ActivityHelper);

export class AnimatedActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colors: props.colors,
      tweener: new Animated.Value(0)
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { colors, animate } = props;
    const tweener = new Animated.Value(0);
    const opacityTweener = new Animated.Value(0);
    return {
      colors,
      tweener,
      opacityTweener,
      animate
    };
  }

  componentDidUpdate() {
    const { tweener, opacityTweener, animate } = this.state;
    if (animate) {
      Animated.timing(opacityTweener, {
        toValue: 1,
        duration: 300
      }, {useNativeDriver: true}).start();
      Animated.loop(Animated.timing(tweener, {
        toValue: 1,
        duration: 1000
      }, {useNativeDriver: true})).start();
    } else {
      tweener.stopAnimation();
      Animated.timing(opacityTweener, {
        toValue: 0,
        duration: 300
      }, {useNativeDriver: true}).start();
    }
    
  }

  render() {
    const {
      tweener,
      opacityTweener
    } = this.state;

    const { style } = this.props;

    const distance = tweener.interpolate({
      inputRange: [0, 1],
      outputRange: [-0.2, 1.2]
    });

    const opacity = opacityTweener.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1]
    });
    return (
      <AnimatedActivityHelper distance={distance} opacity={opacity} style={style} />
    );
  }
}