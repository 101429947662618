import * as React from "react";
import { Text, TouchableOpacity, View, useWindowDimensions, Platform, Linking } from "react-native";
import { Input, Icon, Button, Divider, PresenceTransition, Stack, useBreakpointValue } from "native-base";
import { RootStackScreenProps } from "../types";
import { MaterialIcons } from "@expo/vector-icons";
import GoogleLoginButton from "../components/GoogleSignInButton";
import FacebookLoginButton from "../components/FacebookLoginButton";
import deepmerge from "deepmerge";
import { Message } from "../components/Dialog";
import { ApiController, Dialog, Configuration } from "../components/BrandDialog";

export function isValidEmail(email: string = "") {
  return (
    email.match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    ) !== null
  );
}
export default function LoginScreen({ navigation }: RootStackScreenProps<"Root">) {
  const [message, setMessage] = React.useState<Message | undefined>(undefined);
  const [activity, setActivity] = React.useState(false);
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { height, width } = useWindowDimensions();
  const styles = useBreakpointValue({
    base: deepmerge(baseStyles, {
      type: "base",
      dividers: "horizontal",
      direction: "column",
      loginBox: {
        padding: 3,
        paddingTop: 20
      },
      credentialsBox: {
        paddingHorizontal: 10
      },
      header: {
        height: 180,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end"
      },
      logo: {
        top: -100
      },
      createAccountDivider: {
        display: "flex"
      }
    }),
    md: deepmerge(baseStyles, {
      type: "md",
      dividers: "vertical",
      direction: "row",
      logo: {
        top: -80
      },
      loginBox: {
        width: 750
      },
      ssoBox: {
        padding: 20
      },
      header: {
        ...baseStyles.header,
        paddingLeft: 20,
        paddingRight: 20
      }
    })
  });
  return (
    <Dialog activity={activity} message={message}>
      <Stack style={styles.loginBox} direction={styles.direction}>
        <Stack direction="column" style={styles.credentialsBox}>
          <Input
            w="100%"
            placeholder="Email"
            type="email"
            textContentType="username"
            autoCompleteType="email"
            keyboardType="email-address"
            onChange={(evt: any) => {
              setLogin(evt.target.value);
            }}
            InputLeftElement={
              <Icon
                as={<MaterialIcons name="email" />}
                size="sm"
                style={{ marginLeft: 5 }}
                _light={{
                  color: "gray.500"
                }}
                _dark={{
                  color: "gray.300"
                }}
              />
            }
          />
          <Input
            w="100%"
            placeholder="Password"
            textContentType="password"
            autoCompleteType="password"
            secureTextEntry
            onChange={(evt: any) => {
              setPassword(evt.target.value);
            }}
            InputLeftElement={
              <Icon
                size="sm"
                as={<MaterialIcons name="lock" />}
                style={{ marginLeft: 5 }}
                _light={{
                  color: "gray.500"
                }}
                _dark={{
                  color: "gray.300"
                }}
              />
            }
          />
          <TouchableOpacity
            disabled={activity}
            onPress={() => navigation.replace("LostPasswordRequest")}
            style={styles.link}
          >
            <Text style={styles.linkText}>Lost password</Text>
          </TouchableOpacity>
          <View style={styles.ssoButtons}>
            <Button
              disabled={activity || !isValidEmail(login) || password.length < 8}
              style={styles.ssoButton}
              onPress={async () => {
                setActivity(true);
                setMessage(undefined);
                try {
                  let res = await ApiController.login(login, password);
                  if (res.status < 300) {
                    setMessage({
                      type: "SUCCESS",
                      text: "Login successful, redirecting to your app"
                    });
                    setTimeout(() => {
                      Linking.openURL(ApiController.getUrl("/auth/redirect"));
                    }, 1500);
                  } else if (res.status === 404) {
                    setMessage({
                      type: "ERROR",
                      text: "This account does not exist"
                    });
                  } else if (res.status === 403) {
                    setMessage({
                      type: "ERROR",
                      text: "Bad password"
                    });
                  }
                } catch (err) {
                  setMessage({
                    type: "ERROR",
                    text: "An unknown error occured while logging-in"
                  });
                }
                setActivity(false);
              }}
            >
              Login
            </Button>
          </View>
        </Stack>
        <Stack
          style={{ padding: 10 }}
          alignItems="center"
          direction={styles.dividers === "horizontal" ? "row" : "column"}
        >
          <Divider maxHeight="45%" maxWidth="45%" orientation={styles.dividers}></Divider>
          <View style={{ padding: 10 }}>
            <Text>OR</Text>
          </View>
          <Divider maxHeight="45%" maxWidth="45%" orientation={styles.dividers}></Divider>
        </Stack>
        <View style={styles.ssoBox}>
          <Text>Third party authentication</Text>
          {Configuration.sso.includes("Google") ? (
            <View style={styles.ssoButton}>
              <GoogleLoginButton
                onPress={() => {
                  if (Platform.OS === "web") {
                    Linking.openURL(ApiController.getUrl("/auth/google"));
                  } else {
                    setMessage({
                      type: "WARN",
                      text: "Google is off on mobile for now"
                    });
                  }
                }}
              />
            </View>
          ) : undefined}
          {Configuration.sso.includes("facebook") ? (
            <View style={styles.ssoButton}>
              <FacebookLoginButton
                onPress={() => {
                  setMessage({
                    type: "ERROR",
                    text: "Bad social network"
                  });
                }}
              />
            </View>
          ) : undefined}
        </View>
      </Stack>
      <Divider orientation="horizontal" style={styles.createAccountDivider} />
      <View style={{ alignItems: "center", paddingBottom: 10 }}>
        <Button
          style={{ maxWidth: 200 }}
          disabled={activity}
          onPress={() => {
            if (Configuration.postRegistration) {
              navigation.navigate("Register");
            } else {
              navigation.navigate("NewRegistration");
            }
          }}
        >
          Create an account
        </Button>
      </View>
    </Dialog>
  );
}

const baseStyles = {
  ssoBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  createAccountDivider: {
    display: "none",
    margin: 10
  },
  header: {
    backgroundColor: "#404041",
    color: "white",
    borderWidth: 2,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 30
  },
  headerPanel: {
    alignContent: "center",
    justifyContent: "center"
  },
  ssoButton: {
    margin: 5
  },
  ssoButtons: {
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "space-between"
  },
  credentialsBox: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  loginBox: {
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white"
  },
  link: {
    alignItems: "flex-end",
    marginBottom: 30
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7"
  }
};
